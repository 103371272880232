@if (!popupMode()) {
  <div class="p-16 cursor-pointer" fxLayout="column" fxLayoutGap="12px" (click)="openProject()">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <svg-icon class="svg-24 primary-colors-accent-blue" [src]="'projects' | iconPath: 'other'" />
      <div fxFlex>
        <div class="mb-4" fxLayout="row" fxLayoutAlign="start center">
          <div class="labels-label-s greyscale-light-text">{{ getDate(notification().createdAt) }}</div>
        </div>
        <div class="mb-4" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="labels-label-s greyscale-light-text">Invite rejected</div>
        </div>
        <div fxLayout="row">
          <div class="labels-label-l greyscale-darkest-grey">
            <strong>{{ notification().payload.organization.name }}</strong> rejected your invite to collaborate on
            <strong>{{ notification().payload.project.name }}</strong
            >.
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        @if (!notification().seen) {
          <div fxFlexAlign="center center" class="not-seen mx-8"></div>
        }
        @if (unseenCount() > 0) {
          <div fxFlexAlign="center center" class="not-seen-count mx-8">
            {{ unseenCount() }}
          </div>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="cursor-pointer" fxLayout="row" fxLayoutGap="20px" (click)="openProject()">
    <svg-icon class="svg-24 primary-colors-accent-blue" [src]="'projects' | iconPath: 'other'" />
    <div fxFlex>
      <div class="mb-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="labels-label-s greyscale-darkest-grey">Invite rejected</div>
      </div>
      <div fxLayout="row">
        <div fxFlex class="labels-label-l greyscale-darkest-grey">
          <strong>{{ notification().payload.organization.name }}</strong> rejected your invite to collaborate on
          {{ notification().payload.project.name }}.
        </div>
      </div>
    </div>
    <svg-icon
      matTooltip="Clear"
      matTooltipClass="icon-tooltip"
      class="svg-24"
      [src]="'close' | iconPath: 'basic'"
      (click)="onDeleteNotification($event)"
    />
  </div>
}
