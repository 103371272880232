import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, Output, signal } from '@angular/core';
import {
  COLLABORATOR_TYPE_NAMES,
  FlexGapDirective,
  FlexLayoutAlignDirective,
  IconPathPipe,
  NotificationCollaboratorInvited,
} from 'ngx-q360-lib';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ResolveCollaboratorInvitation } from '@project/store/selected-project/selected-project.actions';
import { DeleteNotification } from '@app/store/notification/notification.actions';
import { getNotificationDate } from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@global-shared/dialogs/confirm-dialog/confirm-dialog.component';
import { GetProject } from '@app/store/project/project.actions';
import { take } from 'rxjs';
import { Router } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { SvgIconComponent } from 'angular-svg-icon';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';

@Component({
  selector: 'app-new-collaboration-invite-notification',
  templateUrl: './new-collaboration-invite-notification.component.html',
  styleUrls: ['../../notification/notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FlexGapDirective,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    FlexLayoutAlignDirective,
    SvgIconComponent,
    MatTooltip,
    IconPathPipe,
  ],
})
export class NewCollaborationInviteNotificationComponent {
  protected readonly getDate = getNotificationDate;
  protected readonly collaboratorTypeNames = COLLABORATOR_TYPE_NAMES;

  private store = inject(Store);
  private matDialog = inject(MatDialog);
  private actions$ = inject(Actions);
  private router = inject(Router);

  notification = input.required<NotificationCollaboratorInvited>();
  popupMode = input<boolean>(false);
  unseenCount = input(0);

  @Output() deleteNotification: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickNotification: EventEmitter<void> = new EventEmitter<void>();

  panelExpanded = signal<boolean>(false);

  onDeleteNotification(event: any): void {
    event.stopPropagation();
    this.deleteNotification.emit(this.notification().id);
  }

  onRejectInvitation(invitationId: string) {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: 473,
      data: {
        title: 'Reject invitation?',
        text: 'Are you sure you want to reject this project invitation? You will not be able to access to the mentioned project.',
        cancelButtonText: 'Cancel',
        saveButtonText: 'Reject',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          new ResolveCollaboratorInvitation(invitationId, this.notification().payload.project.id, false),
        );
        this.store.dispatch(new DeleteNotification({ notificationId: this.notification().id }));
      }
    });
  }

  onAcceptInvitation(invitationId: string) {
    this.store.dispatch(new ResolveCollaboratorInvitation(invitationId, this.notification().payload.project.id, true));
    this.store.dispatch(new DeleteNotification({ notificationId: this.notification().id }));
    this.actions$.pipe(ofActionSuccessful(GetProject), take(1)).subscribe(() => {
      void this.router.navigate([`/pages/project/${this.notification().payload.project.id}`]);
    });
  }
}
