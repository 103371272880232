import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { IconPathPipe, NotificationDecisionUpdated } from 'ngx-q360-lib';
import { MarkNotificationAsRead } from '@app/store/notification/notification.actions';
import {
  getDecisionUpdatedFieldInfo,
  getNotificationDate,
} from '@app/shared/notifications-shared/components/notifications/notifications.utils';
import { MatTooltip } from '@angular/material/tooltip';
import { SvgIconComponent } from 'angular-svg-icon';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
  selector: 'app-decision-updated-notification',
  templateUrl: './decision-updated-notification.component.html',
  styleUrls: ['../../notification/notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, SvgIconComponent, MatTooltip, IconPathPipe],
})
export class DecisionUpdatedNotificationComponent {
  protected readonly getDate = getNotificationDate;
  private store = inject(Store);
  private router = inject(Router);

  notification = input.required<NotificationDecisionUpdated>();
  popupMode = input<boolean>(false);
  grouped = input(false);
  unseenCount = input(0);
  showAllDetails = input(true);

  @Output() deleteNotification: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickNotification: EventEmitter<void> = new EventEmitter<void>();

  notifInfo = computed(() => getDecisionUpdatedFieldInfo(this.notification()));

  openDecision(): void {
    this.store.dispatch(new MarkNotificationAsRead(this.notification()));
    void this.router.navigate([
      `/pages/project/${this.notification().payload.workItem.projectId}/decisions-kan-ban/${this.notification().payload.workItem.id}/fullscreen`,
    ]);
    this.clickNotification.emit();
  }

  onDeleteNotification(event: any): void {
    event.stopPropagation();
    this.deleteNotification.emit(this.notification().id);
  }
}
