import {
  NotificationDecisionApproved,
  NotificationDecisionMaker,
  NotificationDecisionUpdated,
  NotificationTaskAssignedToGroup,
  NotificationTaskAssignedToMe,
  NotificationTaskOwnedByMe,
  NotificationTaskUpdated,
} from 'ngx-q360-lib';
import { DecisionUpdatedField, ProjectTaskUpdatedField } from 'ngx-q360-lib';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getFormattedDate = (date: Date, prefomattedDate: string | boolean = false, hideYear = false): string => {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes: number | string = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day}. ${month} at ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day}. ${month} ${year}. at ${hours}:${minutes}`;
};

export const getNotificationDate = (dateParam: string): string | null => {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today.getTime() - DAY_IN_MS);
  const seconds = Math.round((today.getTime() - Number(date)) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, 'Today'); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10. January at 10:20
  }

  return getFormattedDate(date);
};

export const getProjectTaskUpdatedFieldInfo = (
  notification:
    | NotificationTaskUpdated
    | NotificationTaskAssignedToMe
    | NotificationTaskAssignedToGroup
    | NotificationTaskOwnedByMe,
): string => {
  switch (notification.payload.updatedField) {
    case ProjectTaskUpdatedField.TITLE_UPDATED:
      return ' updated the title.';
    case ProjectTaskUpdatedField.DESCRIPTION_UPDATED:
      return ' updated the description.';
    case ProjectTaskUpdatedField.STATE_UPDATED:
      return ' updated the status.';
    case ProjectTaskUpdatedField.ASSIGNEE_UPDATED:
      return ' updated the assignee.';
    case ProjectTaskUpdatedField.OWNER_UPDATED:
      return ' updated the owner.';
    case ProjectTaskUpdatedField.DUE_DATE_UPDATED:
      return ' updated the due date.';
    case ProjectTaskUpdatedField.IMPORTANCE_UPDATED:
      return ' updated the importance.';
    case ProjectTaskUpdatedField.TAGS_UPDATED:
      return ' updated the tags.';
    case ProjectTaskUpdatedField.HOURS_ESTIMATION_UPDATED:
      return ' updated the hours estimate.';
    default:
      return ' updated the task.';
  }
};

export const getDecisionUpdatedFieldInfo = (
  notification: NotificationDecisionUpdated | NotificationDecisionMaker | NotificationDecisionApproved,
): string => {
  switch (notification.payload.updatedField) {
    case DecisionUpdatedField.TITLE_UPDATED:
      return ' updated the title.';
    case DecisionUpdatedField.DESCRIPTION_UPDATED:
      return ' updated the description.';
    case DecisionUpdatedField.AFFECTED_GROUPS_UPDATED:
      return ' updated the affected groups.';
    case DecisionUpdatedField.IMPORTANCE_UPDATED:
      return ' updated the importance.';
    case DecisionUpdatedField.TAGS_UPDATED:
      return ' updated the tags.';
    case DecisionUpdatedField.DUE_DATE_UPDATED:
      return ' updated the due date.';
    default:
      return ' updated the decision.';
  }
};
