@if (!popupMode()) {
  <div class="p-16 cursor-pointer" fxLayout="column" fxLayoutGap="12px" (click)="openDecision()">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <svg-icon class="svg-24" [src]="'user' | iconPath: 'users'" />
      <div fxFlex>
        <div class="mb-4" fxLayout="row" fxLayoutAlign="start center">
          <div class="labels-label-s greyscale-light-text">{{ getDate(notification().createdAt) }}</div>
        </div>
        @if (showAllDetails()) {
          <div class="mb-4" fxLayout="row" fxLayoutGap="8px">
            <svg-icon class="svg-16" [src]="'tasks' | iconPath: 'other'" />
            <div class="labels-label-s greyscale-light-text">
              {{ workItem()?.code }}
              <span class="labels-label-s greyscale-darkest-grey">{{ workItem()?.title }}</span>
            </div>
          </div>
        }
        <div fxLayout="row">
          <div class="labels-label-l greyscale-darkest-grey">
            <strong>{{ notification().payload.source?.name }}</strong> assigned a decision to you.
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        @if (!notification().seen) {
          <div fxFlexAlign="center" class="not-seen mx-8"></div>
        }
        @if (unseenCount() > 0) {
          <div fxFlexAlign="center" class="not-seen-count mx-8">
            {{ unseenCount() }}
          </div>
        }
      </div>
    </div>
  </div>
} @else {
  <div class="cursor-pointer" fxLayout="row" fxLayoutGap="20px" (click)="openDecision()">
    <svg-icon class="svg-24" [src]="'user' | iconPath: 'users'" />
    <div fxFlex>
      <div class="mb-4" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <svg-icon class="svg-16" [src]="'tasks' | iconPath: 'other'" />
        <div class="labels-label-s greyscale-light-text">{{ workItem()?.code }}</div>
      </div>
      <div class="mb-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="labels-label-s greyscale-darkest-grey">{{ workItem()?.title }}</div>
      </div>
      <div fxLayout="row">
        <div fxFlex class="labels-label-l greyscale-darkest-grey">
          <strong>{{ notification().payload.source?.name }}</strong> assigned a decision to you.
        </div>
      </div>
    </div>
    <svg-icon
      matTooltip="Clear"
      matTooltipClass="icon-tooltip"
      class="svg-24"
      [src]="'close' | iconPath: 'basic'"
      (click)="onDeleteNotification($event)"
    />
  </div>
}
