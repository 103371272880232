@if (!popupMode()) {
  <div class="p-16 cursor-pointer flex-column greyscale-background-grey-bg">
    <div class="flex-column" flexGap="4px">
      <div class="labels-label-s greyscale-light-text">{{ getDate(notification().createdAt) }}</div>
      <div class="labels-label-l greyscale-darkest-grey">
        You’ve been invited to collaborate as a
        <strong>{{ collaboratorTypeNames[notification().payload.collaboratorType] }}</strong> on the project
        <strong>{{ notification().payload.project.mainProject.name }}-{{ notification().payload.project.name }}</strong
        >.
      </div>
      <div>
        <mat-accordion class="plain-accordion">
          <mat-expansion-panel (opened)="panelExpanded.set(true)" (closed)="panelExpanded.set(false)">
            <mat-expansion-panel-header>
              <mat-panel-title class="p-0">
                <div class="flex-column">
                  <div class="labels-label-m primary-colors-accent-blue">
                    @if (panelExpanded()) {
                      Hide details
                    } @else {
                      Show details
                    }
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="flex-column my-20" flexGap="24px">
              <div class="flex-column">
                <div class="labels-label-s greyscale-light-text">Invitation by</div>
                <div class="labels-label-l greyscale-darkest-grey">{{ notification().payload.userName }}</div>
                <div class="labels-label-s greyscale-light-text">{{ notification().payload.organizationName }}</div>
              </div>
              <div class="flex-column">
                <div class="labels-label-s greyscale-light-text">Contract conditions</div>
                <div class="labels-label-l greyscale-darkest-grey">{{ notification().payload.contractType }}</div>
              </div>
              @if (notification().payload.message) {
                <div class="flex-column">
                  <div class="labels-label-s greyscale-light-text">Message</div>
                  <div class="labels-label-l greyscale-darkest-grey">{{ notification().payload.message }}</div>
                </div>
              }
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <div class="flex-row" flexLayoutAlign="end center" flexGap="16px">
          <button class="tertiary-button has-icon" (click)="onRejectInvitation(notification().payload.invitationId)">
            Reject
            <svg-icon [src]="'close' | iconPath: 'basic'" />
          </button>
          <button class="primary-button has-icon" (click)="onAcceptInvitation(notification().payload.invitationId)">
            Accept
            <svg-icon [src]="'checkmark' | iconPath: 'basic'" />
          </button>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="cursor-pointer flex-row" flexGap="20px">
    <div class="labels-label-l greyscale-darkest-grey">
      You’ve been invited to collaborate as a
      <strong>{{ collaboratorTypeNames[notification().payload.collaboratorType] }}</strong> on the project
      <strong>{{ notification().payload.project.mainProject.name }} -{{ notification().payload.project.name }}</strong
      >.
    </div>
    <svg-icon
      matTooltip="Clear"
      matTooltipClass="icon-tooltip"
      class="svg-24"
      [src]="'close' | iconPath: 'basic'"
      (click)="onDeleteNotification($event)"
    />
  </div>
}
