@if (notification(); as notification) {
  <div class="notification-box">
    @switch (notification.notificationType) {
      @case (notificationType.TaskAssignedToMe) {
        <app-task-assigned-to-me-notification
          [testId]="testIds.NotificationsTaskAssignedToMe"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.TaskAssignedToGroup) {
        <app-task-assigned-to-group-notification
          [testId]="testIds.NotificationsTaskAssignedToGroup"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.TaskUpdated) {
        <app-task-updated-notification
          [testId]="testIds.NotificationsTaskUpdated"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.TaskMention) {
        <app-task-mention-notification
          [testId]="testIds.NotificationsTaskMention"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.CommentCreated) {
        <app-task-comment-created-notification
          [testId]="testIds.NotificationsTaskCommentCreated"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.CollaboratorAccepted) {
        <app-invite-accepted-notification
          [testId]="testIds.NotificationsCollaboratorInviteAccepted"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.CollaboratorInvited) {
        <app-new-collaboration-invite-notification
          [testId]="testIds.NotificationsCollaboratorInvited"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.MainProjectOwnerInvited) {
        <app-new-owner-invite-notification
          [testId]="testIds.NotificationsMainProjectOwnerInvited"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.MainProjectOwnerAccepted) {
        <app-main-project-owner-invite-accepted-notification
          [testId]="testIds.NotificationsMainProjectOwnerAccepted"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.MainProjectOwnerRejected) {
        <app-main-project-owner-invite-rejected-notification
          [testId]="testIds.NotificationsMainProjectOwnerRejected"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.MainProjectCoOwnerInvited) {
        <app-new-owner-invite-notification
          [testId]="testIds.NotificationsMainProjectCoOwnerInvited"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.MainProjectCoOwnerAccepted) {
        <app-main-project-co-owner-notification
          [testId]="testIds.NotificationsMainProjectCoOwnerAccepted"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.MainProjectCoOwnerRejected) {
        <app-main-project-owner-invite-rejected-notification
          [testId]="testIds.NotificationsMainProjectCoOwnerRejected"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.CollaboratorRejected) {
        <app-invite-rejected-notification
          [testId]="testIds.NotificationsCollaboratorInviteRejected"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.AssignedPosition) {
        <app-assigned-position-notification
          [testId]="testIds.NotificationsAssignedPosition"
          [notification]="notification"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.TaskOwnedByMe) {
        <app-task-owned-by-me-notification
          [testId]="testIds.NotificationsTaskOwnedByMe"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotifications)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      <!-- DECISION -->
      @case (notificationType.DecisionMaker) {
        <app-decision-maker-notification
          [testId]="testIds.NotificationsDecisionMaker"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.DecisionUpdated) {
        <app-decision-updated-notification
          [testId]="testIds.NotificationsDecisionUpdated"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.DecisionCommentCreated) {
        <app-decision-comment-created-notification
          [testId]="testIds.NotificationsDecisionCommentCreated"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.DecisionMention) {
        <app-decision-mention-notification
          [testId]="testIds.NotificationsDecisionMention"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      @case (notificationType.DecisionApproved) {
        <app-decision-approved-notification
          [testId]="testIds.NotificationsDecisionApproved"
          [notification]="notification"
          [showAllDetails]="showAllDetails()"
          [popupMode]="popupMode()"
          [unseenCount]="unseenCount()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
      <!-- SUPPORT -->
      @case (notificationType.SupportItemCreated) {
        <app-support-item-created-notification
          [testId]="testIds.NotificationsSupportItemCreated"
          [notification]="notification"
          [popupMode]="popupMode()"
          (deleteNotification)="onCloseNotifications($event)"
          (clickNotification)="onClickNotification()"
        />
      }
    }
  </div>
}
