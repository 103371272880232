import { Component, EventEmitter, input, Output } from '@angular/core';
import { Notification, NotificationType, TestIdDirective, TestIds } from 'ngx-q360-lib';
import { SupportItemCreatedNotificationComponent } from '../support-notification-types/support-item-created-notification/support-item-created-notification.component';
import { DecisionApprovedNotificationComponent } from '../decision-notification-types/decision-approved-notification/decision-approved-notification.component';
import { DecisionMentionNotificationComponent } from '../decision-notification-types/decision-mention-notification/decision-mention-notification.component';
import { DecisionCommentCreatedNotificationComponent } from '../decision-notification-types/decision-comment-created-notification/decision-comment-created-notification.component';
import { DecisionUpdatedNotificationComponent } from '../decision-notification-types/decision-updated-notification/decision-updated-notification.component';
import { DecisionMakerNotificationComponent } from '../decision-notification-types/decision-maker-notification/decision-maker-notification.component';
import { TaskOwnedByMeNotificationComponent } from '../task-notification-types/task-owned-by-me-notification/task-owned-by-me-notification.component';
import { AssignedPositionNotificationComponent } from '../project-notification-types/assigned-position-notification/assigned-position-notification.component';
import { CollaboratorInviteRejectedNotificationComponent } from '../project-notification-types/collaborator-invite-rejected-notification/collaborator-invite-rejected-notification.component';
import { MainProjectCoOwnerNotificationComponent } from '../project-notification-types/main-project-co-owner-notification/main-project-co-owner-notification.component';
import { MainProjectOwnerInviteRejectedNotificationComponent } from '../project-notification-types/main-project-owner-invite-rejected-notification/main-project-owner-invite-rejected-notification.component';
import { MainProjectOwnerInviteAcceptedNotificationComponent } from '../project-notification-types/main-project-owner-invite-accepted-notification/main-project-owner-invite-accepted-notification.component';
import { MainProjectOwnerInviteNotificationComponent } from '../project-notification-types/main-project-owner-invite-notification/main-project-owner-invite-notification.component';
import { NewCollaborationInviteNotificationComponent } from '../project-notification-types/new-collaboration-invite-notification/new-collaboration-invite-notification.component';
import { CollaboratorInviteAcceptedNotificationComponent } from '../project-notification-types/collaborator-invite-accepted-notification/collaborator-invite-accepted-notification.component';
import { TaskCommentCreatedNotificationComponent } from '../task-notification-types/task-comment-created-notification/task-comment-created-notification.component';
import { TaskMentionNotificationComponent } from '../task-notification-types/task-mention-notification/task-mention-notification.component';
import { TaskUpdatedNotificationComponent } from '../task-notification-types/task-updated-notification/task-updated-notification.component';
import { TaskAssignedToGroupNotificationComponent } from '../task-notification-types/task-assigned-to-group-notification/task-assigned-to-group-notification.component';
import { TaskAssignedToMeNotificationComponent } from '../task-notification-types/task-assigned-to-me-notification/task-assigned-to-me-notification.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [
    TaskAssignedToMeNotificationComponent,
    TestIdDirective,
    TaskAssignedToGroupNotificationComponent,
    TaskUpdatedNotificationComponent,
    TaskMentionNotificationComponent,
    TaskCommentCreatedNotificationComponent,
    CollaboratorInviteAcceptedNotificationComponent,
    NewCollaborationInviteNotificationComponent,
    MainProjectOwnerInviteNotificationComponent,
    MainProjectOwnerInviteAcceptedNotificationComponent,
    MainProjectOwnerInviteRejectedNotificationComponent,
    MainProjectCoOwnerNotificationComponent,
    CollaboratorInviteRejectedNotificationComponent,
    AssignedPositionNotificationComponent,
    TaskOwnedByMeNotificationComponent,
    DecisionMakerNotificationComponent,
    DecisionUpdatedNotificationComponent,
    DecisionCommentCreatedNotificationComponent,
    DecisionMentionNotificationComponent,
    DecisionApprovedNotificationComponent,
    SupportItemCreatedNotificationComponent,
  ],
})
export class NotificationComponent {
  protected readonly testIds = TestIds;
  protected readonly notificationType = NotificationType;

  notification = input.required<Notification>();
  popupMode = input(false);
  unseenCount = input(0);
  showAllDetails = input(true);
  @Output() closeNotifications: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickNotification: EventEmitter<void> = new EventEmitter<void>();

  onCloseNotifications(id: string): void {
    this.closeNotifications.emit(id);
  }

  onClickNotification(): void {
    this.clickNotification.emit();
  }
}
